<div class="header-container">
	<img
		src="assets/scripta-color.svg"
		alt="logo"
	/>
	<div class="buttons-panel">
		@for (link of links(); track link.title) {
			<a
				class="button"
				routerLinkActive="active"
				[title]="link.title"
				[routerLink]="link.route"
			>
				<mat-icon [svgIcon]="link.icon" />
				<span class="button-title">{{ link.title }}</span>
			</a>
		}
	</div>
	<div class="auth-buttons">
		<a
			class="button small"
			routerLinkActive="active"
			title="Account"
			[routerLink]="routePaths.profile"
		>
			<mat-icon svgIcon="account" />
			<span class="button-title">Account</span>
		</a>
		<button
			mat-flat-button
			class="logout"
			color="accent"
			title="Logout"
			type="button"
			(click)="logout()"
		>
			<mat-icon>exit_to_app</mat-icon>
		</button>
	</div>
</div>
