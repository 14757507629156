import { ChangeDetectionStrategy, Component, DestroyRef, inject, input } from '@angular/core';
import { Router, RouterLinkActive, RouterLink } from '@angular/router';
import { MatIcon } from '@angular/material/icon';
import { MatButton } from '@angular/material/button';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { NavigationLink } from '@scriptac/common/core/models/navigation-link';
import { AppConfigService } from '@scriptac/common/core/services/app-config.service';
import { CurrentUserService } from '@scriptac/common/core/services/current-user.service';
import { emptyArrayIfNull } from '@scriptac/common/core/utils/empty-array-if-null';
import { routePaths } from 'projects/web/src/app/route-paths';

/** Header component. */
@Component({
	selector: 'scriptaw-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		RouterLinkActive,
		RouterLink,
		MatIcon,
		MatButton,
	],
})
export class HeaderComponent {
	private readonly destroyRef = inject(DestroyRef);

	private readonly currentUserService = inject(CurrentUserService);

	private readonly appConfigService = inject(AppConfigService);

	private readonly router = inject(Router);

	/** Links for buttons panel. */
	public readonly links = input.required<NavigationLink[], NavigationLink[] | null>({ transform: emptyArrayIfNull });

	/** Route paths. */
	public readonly routePaths = routePaths;

	/** Logout current user. */
	public logout(): void {
		this.currentUserService
			.logout()
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe(() => {
				if (this.appConfigService.isProduction) {
					// Redirect to external resource.
					window.location.href = this.appConfigService.scriptaUrl;
				} else {
					this.router.navigate(routePaths.welcome);
				}
			});
	}
}
